import commonAct from '@/commonActionHandle.js';
import baseComponent from "@/scripts/baseComponent";
import vmServerApi from '@/api/gpuHub/vmServer';
import { debounce } from 'vue-debounce';
const queryString = require('query-string');
import vmMachineApi from '@/api/gpuHub/vmMachines';
import lazyLoadComponent from '@/scripts/lazyLoadComponent';
import SkeletonBox from '@/components/SkeletonBox';

const { io } = require("socket.io-client");
import { socketIO } from "@/constant/config";
import moment from 'moment';

export default {

    extends: baseComponent,
    components: {
        detailServer: lazyLoadComponent({
            componentFactory: () => import("@/views/gpuHub/vmMachines/vmServerDetail"),
            loading: SkeletonBox,
        })
    },
    props: {
        cardHeight: {
            type: String,
            default: 'height: calc(100dvh - 5rem);'
        },
    },
    data() {
        return {
            debounceFilterFnc: null,
            intervalUpdate : null,
            pagination: {
                isFree: null,
                isFixed: null,
                isAvailable: null,
                deleted: null,
                packageId: null,
                operatingSystem: null,
                isGpuError : null,
                isCpuError : null,
                isNicError : null,
                isOverheat : null,
                querySearch: '',
                orderBy: 'ID',
                directionSort: 'ASC',
                pageIndex: 1,
                pageSize: 300,
                outRowsNumber: 0,
            },
            isLoadingList: false,
            listData: [],
            rentalPackage : [],
            socket: null,
            hardwareDataDictionary: {},
            dialogDetailVisible: false,
            detailServerItem: null,
            serverStateInfomations: []
        };
    },
    computed: {        
        machinePackageFilter() {
            return this.rentalPackage.map(x => {
                return { id: x.id, text: x.name, data : `${x.numberCard} ${x.detailGraphic}` };
            });
        },
        machinePackageMapper() {
            return this.rentalPackage.reduce(function (acc, cur, i) {
                acc[cur.id] = cur;
                return acc;
            }, {});
        },
        //countServerCPUErrors() {
        //    try {
        //        responseData.forEach(itemX => {
        //            try {
        //                this.$set(itemX, "hardwareData", this.hardwareDataDictionary[itemX.macAddress]);
        //            } catch (e) {
        //                console.log(e);
        //            }
        //        });
        //        return (hardwareData.CPU && this.isCpuOverhead(hardwareData.CPU))
        //                || (hardwareData.NetworkCardInfo && this.networkError(hardwareData.NetworkCardInfo))
        //                || (hardwareData.GpuNvidia && (this.isGpuOverhead(hardwareData.GpuNvidia)
        //                    || this.countCardAvailable(hardwareData.GpuNvidia) !== this.machinePackageMapper[packId].numberCard));
            
        //    } catch (error) {
        //        return false;
        //    }
        //},
    },
    created() {
        this.loadRentalPackage();
        this.getServerList(this.pagination);
        this.getServerStateInfo();
        this.debounceFilterFnc = debounce(() => {
            if (this.pagination.isFree === false) this.pagination.isFree = null;
            if (this.pagination.isFixed === false) this.pagination.isFixed = null;
            if (this.pagination.isAvailable === false) this.pagination.isAvailable = null;
            if (this.pagination.deleted === false) this.pagination.deleted = null;
            this.getServerList(this.pagination);
            this.getServerStateInfo();
        }, 1000);

        this.intervalUpdate = setInterval(() => {
            this.getServerStateInfo();
        }, 30000);
    },
    mounted() {
        this.socket = io(socketIO.urlServerState, { forceNew: true });
        this.socket.on("connection", (socket) => {
            console.log(`Connected to ${socketIO.urlServerState}`);
            console.log(socket.handshake.query);
        });
        this.socket.on(socketIO.eventServerState, (data) => {
            if (data.event === "message") {
                var findElement = this.listData.find(x => x.macAddress === data.data.MacAddress);
                if (findElement && findElement !== null) { 
                    this.$set(findElement, "message", data.data.Message);
                    this.$set(findElement, "rdpPort", data.data.RdpPort);
                }
            }
        });
        this.socket.on(socketIO.eventServerHardwareState, (data) => {
            try {
                var jsonData = JSON.parse(data);
                if (jsonData.data) this.hardwareDataDictionary[jsonData.macAddress] = jsonData.data;
                var findElement = this.listData.find(x => x.macAddress === jsonData.macAddress);
                if (jsonData.data && findElement && findElement !== null) {
                    this.$set(findElement, "isUbuntuData", false);
                    this.$set(findElement, "hardwareData", jsonData.data);
                    this.$set(findElement, "dataRecving", true);
                    setTimeout(() => {
                        this.$set(findElement, "dataRecving", false);
                    }, 300);
                    this.$set(findElement, "lastSignal", moment.utc());
                }
            } catch (error) {
                console.error(error);
                //this.rentalPackage = [];
            }
        });
        this.socket.on(socketIO.eventServerUbuntuHardwareState, (jsonData) => {
            try {
                console.log(jsonData);
                if (jsonData.data) this.hardwareDataDictionary[jsonData.macAddress] = jsonData.data;
                var findElement = this.listData.find(x => x.macAddress === jsonData.macAddress);
                if (jsonData.data && findElement && findElement !== null) {
                    this.$set(findElement, "isUbuntuData", true);
                    this.$set(findElement, "hardwareUbuntuData", jsonData.data);
                    this.$set(findElement, "dataRecving", true);
                    setTimeout(() => {
                        this.$set(findElement, "dataRecving", false);
                    }, 300);
                    this.$set(findElement, "lastSignal", moment.utc());
                }
            } catch (error) {
                console.error(error);
                //this.rentalPackage = [];
            }
        });
        this.socket.on("close", this.tryReconnect);
        this.socket.connect();
    },
    beforeDestroy() {
        this.socket.disconnect();
        clearInterval(this.intervalUpdate);
    },
    methods: {        
        loadRentalPackage() {
            vmMachineApi.getPackageList().then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    this.rentalPackage = response.data.data.data;
                }
                else {
                    this.rentalPackage = [];
                    if (response.data && response.data.message !== null && response.data.message !== '') {
                        console.error(response.data.message || this.$lang.common.error);
                    }
                }
            }).catch(error => {
                console.error(error);
                this.rentalPackage = [];
            });
        },
        // Refresh
        refresh() {
            this.pagination.isGpuError = null;
            this.pagination.isCpuError = null;
            this.pagination.isNicError = null;
            this.pagination.isOverheat = null;
            this.getServerList(this.pagination);
        },

        // Sort list
        sortList(element) {
            if (this.pagination.directionSort === 'ASC') {
                this.pagination.directionSort = 'DESC'
            } else {
                this.pagination.directionSort = 'ASC'
            }
            let request = {
                queryString: this.pagination.queryString,
                orderBy: element,
                directionSort: this.pagination.directionSort,
                pageIndex: 1,
                pageSize: this.pagination.pageSize,
                outRowsNumber: this.pagination.outRowsNumber,
            };
            this.getServerList(request);
        },

        // Search list
        searchList() {
            this.getServerList(this.pagination);
        },

        // Pagination size change
        handleSizeChange(pageSize) {
            this.pagination.isGpuError = null;
            this.pagination.isCpuError = null;
            this.pagination.isNicError = null;
            this.pagination.isOverheat = null;
            let request = {
                queryString: this.pagination.queryString,
                orderBy: this.pagination.orderBy,
                directionSort: this.pagination.directionSort,
                pageIndex: 1,
                pageSize: pageSize,
                outRowsNumber: this.pagination.outRowsNumber,
            };
            this.getServerList(request);
        },

        // Pagination page change
        handleCurrentChange(element) {
            this.pagination.isGpuError = null;
            this.pagination.isCpuError = null;
            this.pagination.isNicError = null;
            this.pagination.isOverheat = null;
            let request = {
                queryString: this.pagination.queryString,
                orderBy: this.pagination.orderBy,
                directionSort: this.pagination.directionSort,
                pageIndex: element,
                pageSize: this.pagination.pageSize,
                outRowsNumber: this.pagination.outRowsNumber,
            };
            this.getServerList(request);
        },

        // Get server list
        getServerList(request) {
            this.showLoading();
            vmServerApi.getElementsList(request)
                .then((res) => {
                    try {
                        console.log(res);
                        let responseData = res.data.data.data;
                        let pagingItem = res.data.data.pagingItem;
                        responseData.forEach(itemX => {
                            try {
                                this.$set(itemX, "hardwareData", this.hardwareDataDictionary[itemX.macAddress]);
                            } catch (e) {
                                console.log(e);
                            }
                        });
                        this.listData = responseData;
                        this.pagination.orderBy = pagingItem.orderBy;
                        this.pagination.directionSort = pagingItem.directionSort;
                        this.pagination.pageIndex = pagingItem.pageIndex;
                        this.pagination.pageSize = pagingItem.pageSize;
                        this.pagination.outRowsNumber = pagingItem.outRowsNumber;
                    } catch (error) {
                        console.log("getServerList -> error", error)
                    }
                    setTimeout(() => {
                        this.hideLoading();
                    }, 500);
                })
                .catch(error => {
                    try {
                        if (error.name === 'Error') {
                            if (this.$route.path !== '/login') {
                                setTimeout(() => {
                                    location.href = "/login";
                                }, 2000);
                            }
                        } else {
                            switch (error.errorCode) {
                                default:
                                    this.$store.dispatch("common/showUnkownError", error, "Error");
                                    break;
                            }
                        }
                    } catch (error) {
                        console.log("getServerList -> error", error)
                    }
                    setTimeout(() => {
                        this.hideLoading();
                    }, 500);
                });
        },
        // Get server list
        getServerStateInfo() {
            vmServerApi.getServerStateInfo()
                .then((response) => {
                    try {
                        if (response.data && response.data.result === 0 && response.data.data !== null) {
                            this.$set(this, "serverStateInfomations", response.data.data);
                        }
                    } catch (error) {
                        console.log("getServerStateInfo -> error", error)
                    }
                })
                .catch(error => {
                    console.log("getServerStateInfo -> error", error)
                });
        },
        filterErrorServer(type) {
            this.pagination.isGpuError = null;
            this.pagination.isCpuError = null;
            this.pagination.isNicError = null;
            this.pagination.isOverheat = null;
            switch (type) {
                case 1:
                    this.pagination.isGpuError = true;
                    break;
                case 2:
                    this.pagination.isCpuError = true;
                    break;
                case 3:
                    this.pagination.isNicError = true;
                    break;
                case 4:
                    this.pagination.isOverheat = true;
                    break;
            }
            this.getServerList(this.pagination);
        },
        countCardAvailable(gpuNvidia) {
            try {
                //console.log("countCardAvailable gpuNvidia", gpuNvidia, Array.isArray(gpuNvidia));
                if (gpuNvidia) {
                    if (!Array.isArray(gpuNvidia)) return 1;
                    else return gpuNvidia.length;
                }
                else return 0;
            } catch (error) {
                return 0;
            }
        },
        networkError(networkCardInfo) {
            try {
                let networkActivedItems = networkCardInfo.filter(x => x.IpAddress !== null && x.IsUp && (x.IpAddress.includes('192.168.20.') || x.IpAddress.includes('192.168.25.')));
                if (networkActivedItems.length < 2) return (2 - networkActivedItems.length);
                return 0;
            } catch (error) {
                return 0;
            }
        },
        isGpuOverheat(gpuNvidia, isUbuntuData) {
            try {
                let overHeadLimit = 90;
                if (!isUbuntuData) {
                    if (!Array.isArray(gpuNvidia)) {
                        let gpuTemp = parseFloat(gpuNvidia.Sensors.Temperature.find(x => x.Name === 'GPU Core').Value);
                        return gpuTemp > overHeadLimit;
                    }
                    else {
                        let gpuTemps = gpuNvidia.map(x => parseFloat(x.Sensors.Temperature.find(x => x.Name === 'GPU Core').Value));
                        let overheadTemp = gpuTemps.filter(x => x > overHeadLimit);
                        return overheadTemp.length > 0;
                    }
                }
                else {
                    let gpuTemps = gpuNvidia.map(x => parseFloat(x.Sensors.Temperature));
                    let overheadTemp = gpuTemps.filter(x => x > overHeadLimit);
                    return overheadTemp.length > 0;
                }
            } catch (error) {
                return false;
            }
        },
        isCpuOverheat(cpuData, isUbuntuData) {
            try {
                let overHeadLimit = 90;
                if (!isUbuntuData) {
                    if (!Array.isArray(cpuData)) {
                        let cpuTemp = parseFloat(cpuData.Sensors.Temperature.find(x => x.Name === 'CPU Package').Value);
                        return cpuTemp > overHeadLimit;
                    }
                    else {
                        let cpuTemps = cpuData.map(x => parseFloat(x.Sensors.Temperature.find(x => x.Name === 'CPU Package').Value));
                        let overheadTemp = cpuTemps.filter(x => x > overHeadLimit);
                        return overheadTemp.length > 0;
                    }
                }
                else {
                    let cpuTemps = cpuData.map(x => parseFloat(x.Sensors.Temperature));
                    let overheadTemp = cpuTemps.filter(x => x > overHeadLimit);
                    return overheadTemp.length > 0;
                }
            } catch (error) {
                return false;
            }
        },
        isGpuFanError(gpuNvidia, isUbuntuData) {
            try {
                if (!isUbuntuData) {
                    if (!Array.isArray(gpuNvidia)) {
                        let gpuLoad = parseFloat(gpuNvidia.Sensors.Load.find(x => x.Name === 'GPU Core').Value);
                        let gpuFan = parseFloat(gpuNvidia.Sensors.Control.find(x => x.Name === 'GPU Fan').Value);
                        return (gpuFan === 0 && gpuLoad > 5);
                    }
                    else {
                        let listFanErrors = gpuNvidia.filter(x => (parseFloat(x.Sensors.Load.find(x => x.Name === 'GPU Core').Value) > 5
                            && parseFloat(x.Sensors.Control.find(x => x.Name === 'GPU Fan').Value) === 0));
                        return listFanErrors.length > 0;
                    }
                }
                else {
                    let listFanErrors = gpuNvidia.filter(x => (parseFloat(x.Sensors.Load) > 5
                            && parseFloat(x.Sensors.Fan) === 0));
                        return listFanErrors.length > 0;
                }
            } catch (error) {
                return false;
            }
        }, 
        isServerHasErrors(packId, hardwareData) {
            try {
                return (hardwareData.CPU && this.isCpuOverhead(hardwareData.CPU))
                        || (hardwareData.NetworkCardInfo && this.networkError(hardwareData.NetworkCardInfo))
                        || (hardwareData.GpuNvidia && (this.isGpuOverhead(hardwareData.GpuNvidia)
                            || this.countCardAvailable(hardwareData.GpuNvidia) !== this.machinePackageMapper[packId].numberCard));
            
            } catch (error) {
                return false;
            }
        },
        viewDetailInfo(targetItem) {
            this.$set(this, "dialogDetailVisible", true);
            this.$set(this, "detailServerItem", targetItem);
        },
        closeDetailInfo() {
            this.$set(this, "dialogDetailVisible", false);
            this.$set(this, "detailServerItem", null);
            console.log("detailServerItem", this.detailServerItem);
        },
    }
}