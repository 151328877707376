import { gpuHubCnf } from '@/constant/config.js';
import axios from "axios";
const queryString = require('query-string');

export default {
    getElementsList(request) {
        return axios({
            method: "POST",
            data: request,
            url: `${gpuHubCnf.getListServers}`,
            withCredentials: true,
        });
    },
    getServerComment(request) {
        let queryParams = queryString.stringify(request.pagination);
        return axios({
            method: "GET",
            url: `${gpuHubCnf.getServerComment}/${request.serverId}?${queryParams}`,
            withCredentials: true,
        });
    },
    addServerComment(request) {
        return axios({
            method: "POST",
            data: request,
            url: `${gpuHubCnf.addServerComment}`,
            withCredentials: true,
        });
    },
    getServerStateInfo() {
        return axios({
            method: "GET",
            url: `${gpuHubCnf.getServerStateInfo}`,
            withCredentials: true,
        });
    },
    getClientImageStorageInfo() {
        return axios({
            method: "GET",
            url: `${gpuHubCnf.getClientImageStorageInfo}`,
            withCredentials: true,
        });
    }
}
